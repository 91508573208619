html {
  text-align: center;
  background-color: #282c34;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 50vw;
}

@media only screen and (max-width: 600px) {
  .App-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100vw;
  }
}

.App-header {
  font-size: calc(10px + 2vmin);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.App-page-selector {
  min-height: 30vh;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 50px 50px 0 0;
  padding: 10px;
}

.App-page-selection {
  background-color: #282c34;
  color: white;
  padding: 20px;
  border-radius: 20px;
  font-size: 1.5em;
  cursor: pointer;
  margin: 10px;
}

.App-link {
  color: #61dafb;
  cursor: pointer;
}

.App-button {
  color: #61dafb;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  font-size: 1.2em;
  background-color: #282c34;
  font-weight: bolder;
}

.App-button small {
  color: #ffffff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
